// @ts-nocheck
import Local from './local-storage';
import { isObject } from './type';

// 缓存用户定位的key。
const LOCATION_KEY = 'CRC_USER_LOCATION';
// 缓存1天：1*24*60 = 1440
const LOCATION_CACHE_TIME_MINUTE = 1440;

/**
 * @desc 保存经纬度信息到 本地缓存
 * @param {Object} location 定位信息
 * @param {String} location.type 定位类型
 * @param {String} location.lat 经度
 * @param {String} location.lng 纬度
 */
export function setLocationToStorage(location) {
  return Local.set(LOCATION_KEY, JSON.stringify(location), LOCATION_CACHE_TIME_MINUTE);
}

export function getLocationFromStorage() {
  let data = Local.get(LOCATION_KEY) || {};
  return data;
}

export function removeLocationFromStorage() {
  return Local.remove(LOCATION_KEY);
}